<template>
  <div class="report">
    <div class="header">
      <div class="instrument_c_btn baseBtn">
        <div class="titleBox">
          <button class="title">全部</button>
        </div>
        
        <div class="text">最新</div>
        <div class="text">周排行</div>
        <div class="text">月排行</div>
        <div class="serch__box">
          <el-input
            class="report_search-input"
            prefix-icon="el-icon-search"
            placeholder="搜索众测报告"
            v-model="serchVal"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="content">
      <release-layout
        :data="instrumentList"
        :row="4"
        :id="'layout1'"
        :to="path"
        :type="'check1'"
      ></release-layout>
      <div class="button">
        <button @click="more" v-if="total > instrumentList.length">
          加载更多
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import releaseLayout from "../components/releaseLayout.vue";
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      serchVal: "",
      instrumentList: [
       
      ],
      path: {
          url:"/check/report/info",
      },
      list:[],
      total:"",
      pages:""
    };
  },
  components: { releaseLayout },
  created(){
    this.assessCommentList()
  },
  methods:{
    assessCommentList (){
      let data = {
        assessId:"",
        page:1
      }
      check.assessCommentList().then(res=>{
        let newList = [];
        let list = res.data.data.list;
        this.total = res.data.data.total;
        list.forEach((item) => {
          let data = {
            id: item.commentId,
            code:item.commentCode,
            catId:item.assessId,
            author: item.memberName,
            userIcon:item.memberIcon,
            url:item.apparatusFileId,
            time: item.createTime,
            text:item.description,
            userIcon: item.memberIcon,
            newsTitle: item.apparatusName,
            viewCount: item.hots,
            commentCount: item.opinionNum,
            likeCount: item.commentPros,
          }
          newList.push(data);
        });
        this.instrumentList = newList
      })
    }, 
    more() {
      this.pages++;
      
    },
  }
};
</script>
<style lang="scss">
.report {
  .report_search-input {
    width: 179px;
    height: 45px;
    .el-input__inner {
      width: 179px;
      height: 45px;
      border-radius: 30px;
      // padding-right: 50px;
    }
  }
}
</style>
<style lang="scss" scoped>
.report {
  .header {
    margin-top: 33px;
    .baseBtn {
      display: flex;
      align-items: end;
      margin: 35px 0;
      .titleBox{
        width: 208px;
        height: 88px;
        display: flex;
        background: url("../assets/btn-background.png") no-repeat;
        background-position: 0 0;
        background-size: 100%;
        align-items: flex-end;
      }
      .title {
        width: 180px;
        height: 53px;
        background: linear-gradient(
          90deg,
          #0092ff 0%,
          rgba(55, 182, 255, 0.8700000047683716) 100%
        );
        box-shadow: 0px 5px 9px 1px
          rgba(77.99999535083771, 191.00001901388168, 255, 0.25);
        border-radius: 39px 39px 39px 0px;
        border: none;
        font-size: 29px;
        font-weight: 800;
        color: #ffffff;
        font-family: PingFang Regular;
      }
      .text {
        margin: 0 25px;
        font-size: 21px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .serch__box {
        flex-grow: 1;
        text-align: right;
      }
    }
  }
  .button {
      margin-top: 65px;
      margin-bottom: 207px;
      text-align: center;
      button {
        padding: 17px 64px;
        outline: none;
        background: #fff;
        border: 1px solid #0394ff;
        color: #0394ff;
        border-radius: 65px;
        font-size: 21px;
        &:hover {
          background: #0394ff;
          box-shadow: 0px 5px 24px 1px
            rgba(2.9999998211860657, 147.9999303817749, 255, 0.5099999904632568);
          color: #ffffff;
        }
      }
    }
}
</style>